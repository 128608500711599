body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Splash Page */

.splash-overlay {
  backdrop-filter: blur(2px); /* Semi-transparent blur effect */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure the splash page is on top of other elements */
}

.splash-content {
  background-color: rgb(14, 14, 14);
  padding: 30px;
  border-radius: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 130px; /* Adjust the height as needed */
}

.splash-content p {
  margin-top: 0;
  margin-bottom: 20px;
}

.splash-content button {
  padding: 10px 20px;
  border: none;
  background-color: rgb(54, 141, 54);
  color: white;
  font-size: 16px;
  cursor: pointer;
}

/* Instructions Splash Page */

