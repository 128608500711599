.stats-splash-page {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    max-width: 550px;
    width: 100%;
    margin: 0 auto;
    padding: 10px;
    box-sizing: border-box;
    background-color: rgb(0, 0, 0);
    z-index: 7;
  }
  .stats-background {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vmax;
    object-fit: cover;
    z-index: 0;
    margin: 0;
    padding: 0;
    background-color: rgb(0, 0, 0);
  }
  
  .stats-splash-content {
    text-align: center;
    position: relative;
    max-height: 100vh;
    max-width: 100vh;
    background-color: rgb(13, 14, 14);
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 0px;
    padding-bottom: 15px;
    border-radius: 7px;
    margin-top: -80px;
    
    animation: fadeInUp 0.2s ease-in-out forwards; /* Apply fade in and float up animation */
  }

.exit-animation {
    animation: fadeOutDown 0.2s ease-in-out forwards;
  }

  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes fadeOutDown {
    0% {
    opacity: 1;
    transform: translateY(0);
    }
    100% {
    opacity: 0;
    transform: translateY(20px);
    }
  }

    /* Media query to make the splash page responsive */
    @media (max-width: 600px) {
        .splash-page {
          padding: 10px;
        }
      
      }
    
      @font-face {
        font-family: 'Roboto';
        src: url('../fonts/Roboto-Regular.ttf') format('truetype');
        font-weight: 400;
        font-style: normal;
      }
      
      @font-face {
        font-family: 'Roboto';
        src: url('../fonts/Roboto-Medium.ttf') format('truetype');
        font-weight: 500;
        font-style: normal;
      }
      
      @font-face {
        font-family: 'Roboto';
        src: url('../fonts/Roboto-Bold.ttf') format('truetype');
        font-weight: 700;
        font-style: normal;
      }
    
      @font-face {
        font-family: 'Garamond';
        src: url('../fonts/EBGaramond-VariableFont_wght.ttf') format('truetype');
      }
      
    
    .stats-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 17.5px;
        margin: 0 auto;
        margin-top: 19px;
        margin-bottom: -41.5px;
        background-color: #0d0e0e;
        border-radius: 7px;
      }
    
    .stats-2-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
        margin: 0 auto;
        margin-bottom: -18px;
        background-color: #0d0e0e;
        border-radius: 7px;
    }
    
    .individual-stats-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 5px;
        width: 150px;
        margin: 0 auto;
        margin-top: 1.5px;
        margin-left: -1.5px;
        margin-right: 0px;
        background-color: #0d0e0e;
        border: solid 3px #ffc1c1;
      }


    /* heading 1 */
    .stats-heading {
        color: white;
        text-align: center !important;
        font-size: 2.5rem;
        font-family: 'Garamond';
        font-weight: 900;
        text-align: center;
        padding-bottom: -20px;
        margin-bottom: -20px;
        margin-top: 13px;
      }
  
      /* sub-heading */
      .stats-subheading {
        color: white;
        font-size: 1.3rem;
        margin-bottom: 20px;
        font-family: 'Roboto';
        font-weight: 400;
        text-align: left;
        padding-top: 1px;
        margin: 0;
      }

      .stats-counts
      {
        color: white;
        font-size: 3rem;
        margin-bottom: 20px;
        font-family: 'Garamond';
        font-weight: 700;
        text-align: left;
        padding-top: 1px;
        margin: 0;
      }

  
      /* paragraph */
      .stats-paragraph {
        color: white;
        text-align: center !important;
        font-size: 1rem;
        margin-bottom: 20px;
        font-family: 'Roboto';
        font-weight: 500;
        text-align: left;
        font-style: italic;
        text-decoration: underline;
      }
  
      /* guess titles */
      .guess-stats-container {
        display: flex;
        justify-content: center;
        max-width: 300px;
        max-height: fit-content;
        margin: 0 auto;
        margin-bottom: 13px;
        margin-top: 13px;
        padding: 3px 0px;
        border-radius: 3px;
        background-color: #457a25;
      }
  
      .guess-stats-subheading {
          color: white;
          align-content: center;
          font-size: 1.3rem;
          margin-bottom: 1px;
          margin-top: 1px;
          font-family: 'Roboto';
          font-weight: 500;
          padding-left: 5px;
          transform: translateX(-6px);
        }
  
      .stats-list {
          color: white;
          font-size: 1rem;
          margin-bottom: 20px;
          font-family: 'Roboto';
          font-weight: 400;
          text-align: left;
          padding-top: 5px;
          border-radius: 10px;
        }
    
        .stats-note {
      text-align: left;
      font-size: lrem;
      color: white;
      margin-bottom: 15px;
      font-family: 'Roboto'; 
    }

    .stats-button-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 3px 20px;
        margin: 0 auto;
        margin-top: -6px;
        margin-bottom: -6px;
        background-color: transparent;
        cursor: pointer;
      }
      
      .stats-button-container button {
        margin-right: 20px; /* Space between the two buttons */
      }
      
    .share-button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4px 30px;
      padding-top: 5px;
      background-color: #b2ac00;
      color: rgb(0, 0, 0);
      font-size: 1.5rem;
      font-family: 'Roboto';
      font-weight: 700;
      cursor: pointer;
      border: solid 1px #7F0000;
      border-radius: 7px;
      margin-left: 20px;
    }
    .disabled-share-button {
        background-color: #fff700;
    }

    .stats-play-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 2px;
      margin: 0 auto;
      margin-bottom: -13px;
      margin-top: -20px;
      background-color: transparent;
      border-radius: 7px;
      transform: scale(0.8);
    }


    /* Calendar */

.calendar-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 20px;
    margin: 0 auto;
    margin-top: -6px;
    margin-bottom: -6px;
    background-color: transparent;
    cursor: pointer;
  }
.calendar-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 30px;
    padding-top: 5px;
    background-color: #b2ac00;
    color: rgb(0, 0, 0);
    font-size: 1.5rem;
    font-family: 'Roboto';
    font-weight: 700;
    cursor: pointer;
    border-radius: 7px;
  }