.App {
	text-align: center;
	margin-top: 0px;
	max-height: 100vh;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

body {
	background-color: #0f1012;	
	max-height: 100vh;
}

body, html {
	margin: 0;
	padding: 0;
}


@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 1s linear;
	}
}

.App-header {
	background-color: #0f1012;
	display: flex;
	max-height: 100vh;
	min-height: 100vh;
	flex-direction: column;
	align-items: center;
	justify-content: top;
	font-size: calc(10px + 2vmin);
	color: rgb(240, 240, 240);
}

@media (max-height: 700px) {
	.App-header {
		min-height: 170vh;
		max-height: 170vh;
	}
  }

.App-link {
	color: #61dafb;
}

.button {
	margin: 5px;
	padding: 10px;
	font-size: 16px;
}

.guess-button {
	margin: 5px;
	padding: 10px 20px;
	font-size: 16px;
	border: 2px solid #333; /* Add a border */
	border-radius: 8px; /* Add border radius */
	background-color: #07396f; /* Blue background color 07396f */
	color: white; /* Text color */
	cursor: pointer;
	transition: background-color 0.3s, color 0.3s; /* Add transition for hover effect */
  }
  
  .guess-button:hover {
	background-color: #0056b3; /* Darker blue on hover */
  }

.input-box {
	margin: 5px;
	padding: 10px;
	font-size: 16px;
}


.list-item {
	list-style-type: none;
	font-size: 18px;
	margin: 5px;
}

@font-face {
	font-family: 'crumpled';
	src: url('./fonts/crumpled.ttf') format('truetype');
  }

.title-text {
	font-family: 'crumpled';
	font-size: 40px;
	margin-top: 0px; /* Set top margin */
	margin-bottom: 5px; /* Set bottom margin */
  }


  /* Loading Spinner */

.loading-container {
	background-color: #282c34; /* pastel grey */
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh; /* adjust height as needed */
  }
  
  .spinner {
	border: 6px solid #979797; /* Light grey */
	border-top: 6px solid #3498db; /* Blue */
	border-radius: 50%;
	width: 40px;
	height: 40px;
	animation: spin 1s linear infinite; /* Apply rotation animation */
  }
  
  @keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
  }


  /* Font Awesome round button */
.round-button {
	box-sizing: border-box;
	display:block;
	min-width:80px;
	min-height:80px;
	padding-top: 14px;
	padding-left: 8px;
	line-height: 20px;
	border: 6px solid #fff;
	border-radius: 50%;
	color:#f5f5f5;
	text-align:center;
	text-decoration:none;
	background-color: rgba(0, 0, 0, 0.5);
	font-size:20px;
	font-weight:bold;
	transition: all 0.3s ease;
}
.round-button:hover {
	background-color: rgba(0,0,0,0.8);
	box-shadow: 0px 0px 10px rgba(255,255,100,1);
	text-shadow: 0px 0px 10px rgba(255,255,100,1);
}
.disabled-round-button {
	background-color: #ccc; /* Grey background */
	color: #666; /* Darker grey text */
	pointer-events: none; /* Disable pointer events */
  }

  @media (max-height: 670px) {
	.round-button {
	  min-width: 70px;
	  min-height: 70px;
	  padding-top: 10px;
	  font-size: 16px;
	}
	.fa {
		transform: translateY(3px);
	}
  }

/* Top Bar */
.top-bar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 40px;
	min-width: 97.5%;
	padding: 0 20px;
	background-color: #0f1012;
	border-bottom: 1px solid #a7a7a7;
	z-index: 6;
  }
  
  .left-section {
	display: flex;
	align-items: center;
  }
  
  .title {
	font-size: 24px;
	margin-right: 20px;
	color: white;
  }
  
  .right-section {
	display: flex;
	align-items: center;
  }
  
  .question-button,
  .custom-button {
	padding: 8px 1px;
	margin-left: 5px;
	font-size: 0px;
	background-color: rgba(0, 0, 0, 0) !important; /* Transparent background */
	color: white;
	border: none;
	border-radius: 0px;
	cursor: pointer;
  }

  /* GuessResultBox.css */

/* Initial state of the box */
.guess-box {
	position: relative;
	width: 100%;
	height: 100%;
	transition: transform 0.7s;
  }
  
  /* Flipped state of the box */
  .guess-box.flipped {
	transform: rotateX(180deg);
	animation: flip 0.7s;
  }
  
  /* Front and back faces of the box */
  .guess-box > div {
	position: absolute;
	width: 100%;
	height: 100%;
	backface-visibility: hidden;
  }
  
  /* Define the front face of the box */
  .guess-box > div.front {
	z-index: 2;
	transform: rotateX(0deg);
  }
  
  /* Define the back face of the box */
  .guess-box > div.back {
	transform: rotateX(-180deg);
  }
  
  @keyframes flip {
	from {
	  transform: rotateX(0deg);
	}
	to {
	  transform: rotateX(180deg);
	}
  }

  /* CategoryBox */
  .category-box {
	font-weight: bold;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: max-content;
	height: 40px;
	padding-left: 10px;
	padding-right: 10px;
	padding-bottom: 4px;
	border-radius: 5px;
	margin-top: 4px;
	margin-bottom: 10px;
	background-color: #206aff;
	color: white;
	animation: fadeIn 0.4s ease-in-out forwards;
}
.category-wrapper {
	margin-bottom: 8px;
  }

@keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }


  /* SeeStatsButton */
  .see-stats-button {
	margin: 5px;
	margin-bottom: 1px;
	padding: 6px 39.6px;
	font-size: 25px;
	font-weight: bold;
	border: 3px double #4e0000; /* Add a border */
	border-radius: 3px; /* Add border radius */	
	background-color: rgb(255, 234, 0); /* Blue background color 07396f */
	color: rgb(0, 0, 0); /* Text color */
	cursor: pointer;
	transition: background-color 0.3s, color 0.3s; /* Add transition for hover effect */
	animation: fadeIn 0.2s ease-in-out forwards;
  }
  
  .see-stats-button:hover {
	background-color: #ccbb00; /* Darker blue on hover */
  }

  /* Search Results Container laptops */
  .search-results-container {
	overflow-y: auto;
	margin-top: "0px";
	max-height: 300px;
	transform: translateX(-19px);
	overflow-y: auto;
  }

    /* Style the scrollbar */
  .search-results-container::-webkit-scrollbar {
	width: 3px;
	background-color: transparent;
  }
  
  .search-results-container::-webkit-scrollbar-thumb {
	background-color: red;
	border-radius: 3px;
  }

  @media (max-width: 1200px) {
	.search-results-container {
	  display: none;
	}
  }


  /* Search Results Container mobile */
  .search-results-container-mobile {
	overflow-y: auto;
	margin-top: "0px";
	height: 160px;
	width: 95%;
	transform: translateX(-20px);
	margin-bottom: 5px;
	overflow-y: auto;
  }



  /* Style the scrollbar */
  .search-results-container-mobile::-webkit-scrollbar {
	width: 3px;
	background-color: transparent;
  }
  
  .search-results-container-mobile::-webkit-scrollbar-thumb {
	background-color: red;
	border-radius: 3px;
  }

  @media (min-width: 1201px) {
	.search-results-container-mobile {
	  display: none;
	}
	  }