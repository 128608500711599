.instruct-splash-page {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    max-width: 550px;
    width: 100%;
    margin: 0 auto;
    padding: 0px;
    box-sizing: border-box;
    background-color: rgb(0, 0, 0);
    z-index: 5;
  }
  .instruct-background {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vmax;
    object-fit: cover;
    z-index: 0;
    margin: 0;
    padding: 0;
    background-color: rgb(0, 0, 0);
  }
  
  .instruct-splash-content {
    text-align: center;
    position: relative;
    background-color: rgb(44, 70, 70);
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 0px;
    padding-bottom: 15px;
    border-radius: 7px;
    margin-top: -100px;
    max-height: 80vh;
    max-width: 360px;
    overflow-y: auto;
    animation: fadeInUp 0.2s ease-in-out forwards; /* Apply fade in and float up animation */
  }
  .exit-animation {
    animation: fadeOutDown 0.2s ease-in-out forwards;
  }

  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes fadeOutDown {
    0% {
    opacity: 1;
    transform: translateY(0);
    }
    100% {
    opacity: 0;
    transform: translateY(20px);
    }
  }


  .close-button {
    position: absolute;
    top: 0px;
    right: 0px;
    margin: 13px 13px;
    background-color: transparent;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transform: scale(1.5);
  }
  
  /* Media query to make the splash page responsive */
  @media (max-width: 600px) {
    .splash-page {
      padding: 10px;
    }
  }

  @font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Garamond';
    src: url('../fonts/EBGaramond-VariableFont_wght.ttf') format('truetype');
  }
  

    /* heading 1 */
    .instruct-heading {
      color: white;
      font-size: 2rem;
      margin-bottom: 0px;
      font-family: 'Garamond';
      font-weight: 900;
      text-align: left;
      padding-bottom: -20px;
      margin-bottom: -20px;
      text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.619);
    }

    /* sub-heading */
    .instruct-subheading {
      color: white;
      font-size: 1.5rem;
      margin-bottom: 13px;
      font-family: 'Roboto';
      font-weight: 700;
      text-align: left;
      margin-top: 24px;
      text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.619);

    }

    /* paragraph */
    .instruct-paragraph {
      color: white;
      font-size: 1rem;
      margin-bottom: 10px;
      font-family: 'Roboto';
      font-weight: 400;
      text-align: left;
      text-shadow: 3px 3px 6px rgb(0, 0, 0);
    }

    /* guess titles */
    .guess-container {
      display: flex;
      justify-content: left;
      max-width: 300px;
      max-height: 30px;
      margin-bottom: 1px;
      margin-top: 20px;
      padding-left: 1px;
      margin-right: 20px;
      border-radius: 4px !important;
      background-color: #B59410;
    }

    .guess-subheading {
        color: white;
        font-size: 1.3rem;
        margin-bottom: 1px;
        margin-top: 1px;
        font-family: 'Roboto';
        font-weight: 500;
        text-align: left;
        padding-left: 5px;
      }    
      .guess-three {
        background-color: #457a25;
      }

    .instruct-list {
        color: white;
        font-size: 1rem;
        margin-bottom: 20px;
        font-family: 'Roboto';
        font-weight: 400;
        text-align: left;
        padding-top: 5px;
      }


    li {
      padding: 3px 0; /* Add padding between list items */
      font-family: 'Roboto';
    }

    .li-guess {
      padding: 3px 0; /* Add padding between list items */
      margin-bottom: -10px;
      font-weight: 700;
      font-family: 'Roboto';
    }

    .image-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
    }
    
    .image-container img {
      height: auto;
      margin-bottom: -30px;
      padding: 15px;
      padding-bottom: 25px;
    }
    
    .play-image {
      width: 11%;
    }
    
    .search-image {
      width: 39%;
    }
    
    .guess-image {
      width: 30%;
    }
  
      .note {
    text-align: left;
    font-size: lrem;
    color: white;
    margin-bottom: 15px;
    font-family: 'Roboto';
    
  }

  .abstract-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    transform: scale(0.6);
    padding: 0px;
    margin-top: -62px;
    margin-bottom: -70px;
    margin-left: -50px;
    margin-right: -50px;
    padding-left: -50px;
    image-resolution: 1200dpi;
  }

  .close-instructinos-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: -2px;
    margin-top: -2px;
    
  }